import classNames from 'classnames';
import * as React from 'react';

import ImageWithFallback from '../ImageWithFallback/ImageWithFallback';

interface EventCardProps extends React.HTMLProps<HTMLDivElement> {
	children: React.ReactNode;
	onClick?: () => void;
}

const EventCardWrapper = React.forwardRef<HTMLDivElement, EventCardProps>((props, ref) => {
	const { children, ...rest } = props;

	return (
		<div className="flex w-full cursor-pointer flex-col rounded-md bg-white shadow-md" ref={ref} {...rest}>
			{children}
		</div>
	);
});

EventCardWrapper.displayName = 'EventCard';

interface EventCardImageProps extends React.HTMLProps<HTMLDivElement> {
	imageSrc: string;
}

const Image = React.forwardRef<HTMLDivElement, EventCardImageProps>((props) => {
	const { imageSrc, className, alt } = props;

	const classes = classNames('relative', className, {
		'h-24 md:h-44': !className
	});

	return (
		<div className={classes}>
			<ImageWithFallback
				src={imageSrc}
				alt={alt || 'Event Image'}
				fill
				priority
				className="object-contain"
				sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
			/>
		</div>
	);
});

Image.displayName = 'EventCardImage';

interface EventCardContentProps extends React.HTMLProps<HTMLDivElement> {
	children: React.ReactNode;
}

const Content = React.forwardRef<HTMLDivElement, EventCardContentProps>((props, ref) => {
	const { children } = props;

	return (
		<div className="flex flex-1 flex-col p-2" ref={ref}>
			{children}
		</div>
	);
});

Content.displayName = 'EventCardContent';

interface EventCardFooterProps extends React.HTMLProps<HTMLDivElement> {
	children: React.ReactNode;
	borderColorClass?: string;
}

const Footer = React.forwardRef<HTMLDivElement, EventCardFooterProps>((props, ref) => {
	const { children, borderColorClass } = props;

	return (
		<div
			className={classNames('mt-auto flex flex-wrap items-center border-t  px-2 text-sm text-gray-700 md:flex-row', borderColorClass, {
				'border-gray-200': !borderColorClass
			})}
			ref={ref}
		>
			{children}
		</div>
	);
});

Footer.displayName = 'EventCardFooter';

const PlayerCount = ({ children, count = 0, waitingListCount }: { children?: React.ReactNode; count?: number; waitingListCount?: number }) => {
	return (
		<div className="my-2 flex flex-col items-start whitespace-nowrap">
			{children ? (
				<>{children}</>
			) : (
				<>
					<p className="text-xs md:text-base">
						<span className="font-semibold">{count}</span> Players
					</p>
					{waitingListCount !== undefined && (
						<p className="text-xs md:text-base">
							<span className="font-semibold">{waitingListCount}</span> Waiting
						</p>
					)}
				</>
			)}
		</div>
	);
};
const SessionCount = ({ children, count = 0 }: { children?: React.ReactNode; count?: number }) => {
	return (
		<div className="my-2 inline-flex flex-1 items-center whitespace-nowrap">
			{children ? (
				<>{children}</>
			) : (
				<>
					<p className="text-xs md:text-base">
						<span className="font-semibold">{count ? count : ' - '}</span> Session
					</p>
				</>
			)}
		</div>
	);
};
const GameDaysCount = ({ children, count = 0 }: { children?: React.ReactNode; count?: number }) => {
	return (
		<div className=" my-2 mr-4 inline-flex flex-1 items-center whitespace-nowrap">
			{children ? (
				<>{children}</>
			) : (
				<>
					<p className="text-xs md:text-base">
						<span className="font-semibold">{count}</span> Game Days
					</p>
				</>
			)}
		</div>
	);
};

const PlayerProfileImages = ({ images }: { images: string[] }) => {
	return (
		<div className="ml-2 flex md:ml-auto md:mt-0">
			{images.map((item) => {
				return (
					<div
						key={item}
						className="-ml-2 size-6 rounded-full bg-cover ring-2 ring-white"
						style={{
							backgroundImage: `url(${item})`
						}}
					/>
				);
			})}
		</div>
	);
};

export const EventCard = Object.assign(EventCardWrapper, {
	Image,
	Content,
	Footer,
	PlayerCount,
	SessionCount,
	GameDaysCount,
	PlayerProfileImages
});

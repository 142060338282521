import * as React from 'react';

interface AccordionOptions {
	activeEventKey: string[];
	onToggle: (eventKey: string[]) => void;
	canOpenMultiple?: boolean;
}

const useEventKey = (eventKey: string[], onToggle: (eventKey: string[]) => void): [string[], React.Dispatch<string[]>] => {
	const [activeEventKey, setActiveEventKey] = React.useState<string[]>(eventKey);

	React.useLayoutEffect(() => {
		setActiveEventKey(eventKey);
	}, [eventKey, onToggle]);

	return [activeEventKey, setActiveEventKey];
};

const AccordionContext = React.createContext<AccordionOptions | null>(null);

const useAccordionContext = () => {
	const context = React.useContext(AccordionContext);
	if (!context) {
		throw new Error('Accordion components are compound component. Must be used inside Accordion.');
	}
	return context;
};

export const AccordionRoot = ({
	activeEventKey,
	onToggle,
	children,
	element: Component = 'div',
	...otherProps
}: {
	children: React.ReactNode;
	element?: keyof JSX.IntrinsicElements;
} & AccordionOptions) => {
	const [eventKey, setEventKey] = useEventKey(activeEventKey, onToggle);

	const handleToggle = React.useCallback(
		(eventKey: string[]) => {
			if (activeEventKey !== undefined) {
				onToggle(eventKey);
				return;
			}

			setEventKey(eventKey);
		},
		[activeEventKey, onToggle, setEventKey]
	);

	const context = React.useMemo(() => {
		return {
			activeEventKey: eventKey,
			onToggle: handleToggle
		};
	}, [eventKey, handleToggle]);

	return (
		<AccordionContext.Provider value={context}>
			<Component {...otherProps}>{children}</Component>
		</AccordionContext.Provider>
	);
};

const useAccordionClick = (eventKey: string, onClick?: (event: React.MouseEvent) => void) => {
	const { onToggle, activeEventKey } = useAccordionContext();

	return (event: React.MouseEvent) => {
		const toggled = activeEventKey?.some((item) => item === eventKey);
		if (toggled) {
			onToggle(activeEventKey.filter((item) => item !== eventKey));
		} else {
			onToggle([...activeEventKey, eventKey]);
		}

		if (onClick) {
			onClick(event);
		}
	};
};

interface AccordionToggleProps {
	children: React.ReactNode;
	eventKey: string;
	className?: string;
	element?: keyof JSX.IntrinsicElements;
	onClick?: (event: React.MouseEvent) => void;
}

const Toggle = ({ children, element: Component = 'div', className, eventKey, onClick, ...otherProps }: AccordionToggleProps) => {
	const accordionClick = useAccordionClick(eventKey, onClick);

	return (
		<Component onClick={accordionClick} className={className} {...otherProps}>
			{children}
		</Component>
	);
};

interface AccordionCollapseProps {
	children: React.ReactNode;
	eventKey: string | null;
	element?: keyof JSX.IntrinsicElements;
	onClick?: (event: React.MouseEvent) => void;
}

const Collapse = ({ element: Component = 'div', eventKey, children, ...otherProps }: AccordionCollapseProps) => {
	const { activeEventKey } = useAccordionContext();

	return activeEventKey?.some((key) => key === eventKey) ? <Component {...otherProps}>{children}</Component> : null;
};

export const Accordion = Object.assign(AccordionRoot, { Toggle, Collapse });

import classNames from 'classnames';
import * as React from 'react';

type Variants = 'primary' | 'secondary' | 'aslink' | 'info' | 'success' | 'danger' | 'primary2' | 'gray';
type Sizes = 'xs' | 'sm' | 'md' | 'lg';
type ButtonVariantType = 'contained' | 'outlined';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	children: React.ReactNode;
	buttonSize?: Sizes;
	buttonVariantType?: ButtonVariantType;
	variant?: Variants;
	isActive?: boolean;
	smallButton?: boolean;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
	const {
		children,
		buttonSize = 'md',
		variant = 'primary',
		buttonVariantType = 'contained',
		className,
		disabled,
		isActive,
		smallButton,
		...rest
	} = props;

	const classes = classNames(
		smallButton
			? 'rounded-full transition duration-150 px-2 py-1 inline-flex items-center whitespace-nowrap'
			: 'rounded-full transition duration-150 px-4 py-1.5 inline-flex items-center whitespace-nowrap',
		{
			'text-xs px-2': buttonSize === 'xs',
			'text-sm': buttonSize === 'sm',
			'text-base': buttonSize === 'md',
			'text-lg': buttonSize === 'lg',
			// colors
			'bg-darkblue-500 text-white hover:bg-darkblue-700 border border-darkblue-500': variant === 'primary',
			'bg-blue-500 text-white hover:bg-blue-600': variant === 'primary2' && buttonVariantType !== 'outlined' && !disabled,
			'bg-error-400 text-white hover:bg-error-500': variant === 'danger',
			'bg-secondary-500 text-white border border-secondary-500 hover:border-secondary-700 hover:bg-secondary-700':
				variant === 'secondary' && buttonVariantType !== 'outlined' && !disabled,
			'bg-blue-500 text-white border border-blue-500 hover:border-blue-700 hover:bg-blue-700':
				variant === 'info' && buttonVariantType !== 'outlined' && !disabled,
			'bg-success-500 text-white border border-success-500 hover:border-success-700 hover:bg-success-700':
				variant === 'success' && buttonVariantType !== 'outlined' && !disabled,
			'border-secondary-500 border bg-white hover:bg-secondary-500 hover:text-white':
				variant === 'secondary' && buttonVariantType === 'outlined' && !disabled && !isActive,
			'border-secondary-500 border bg-secondary-500 text-white hover:bg-secondary-500 hover:text-white':
				variant === 'secondary' && buttonVariantType === 'outlined' && !disabled && isActive,
			'border-blue-500 border bg-white hover:bg-blue-500 hover:text-white':
				variant === 'primary2' && buttonVariantType === 'outlined' && !disabled && !isActive,
			'border bg-white hover:bg-gray-700 hover:text-white': variant === 'gray' && buttonVariantType === 'outlined' && !disabled && !isActive,
			'bg-gray-200 text-gray-400 hover:bg-gray-200 text-gray-400 cursor-default': disabled,
			'border-gray-200 border': disabled && buttonVariantType === 'outlined',
			'bg-gray-700 text-white border border-gray-700 hover:border-gray-700 hover:bg-gray-700':
				variant === 'gray' && buttonVariantType !== 'outlined' && !disabled,
			'border-gray-700 border bg-gray-700 hover:bg-gray-700 text-white':
				variant === 'gray' && buttonVariantType === 'outlined' && !disabled && isActive
		},
		className
	);

	return (
		<button className={classes} ref={ref} {...rest}>
			{children}
		</button>
	);
});

Button.displayName = 'Button';
